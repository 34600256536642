(function($) {
  "use strict";

  let require_gallery_slideout = function() {
    _gallery.init();
  };

  let _GSO = null;
  let _gallery = {

    __Selector: ".js--slideout-gallery",

    init: function() {
      if (window.ONBN.modules.gallery) {
        _GSO = this;
        _GSO.setup();
        _GSO.events();
      }
    },

    setup: function() {
      document.querySelectorAll(_GSO.__Selector).forEach(_GSO.build);
    },

    build: function(item) {

      const _id = item.getAttribute("id");
      let GData = {
        _id: _id,
        _title: item.dataset.title,
        _container: _id + "_container",
        _e_container: "#" + _id + "_container",
        _data: eval(_id),
        triggeredAdverts: new Set()
      };

      const _gallery_before_close = new CustomEvent(
        "gallery_sidebar_close",
        {
          bubbles: true,
          cancelable: false,
          detail: {
            elementId: GData._id,
            title: GData._title,
            message: "Side Gallery Will be Closed"
          }
        }
      );

      const _gallery_before_open = new CustomEvent(
        "gallery_sidebar_open",
        {
          bubbles: true,
          cancelable: false,
          detail: {
            elementId: GData._id,
            title: GData._title,
            message: "Side Gallery Will be Opened"
          }
        }
      );


      // Open Gallery Event
      $(item).on("click", function() {

        document.dispatchEvent(_gallery_before_open);

        $("#" + GData._container).fadeIn(500, function() {
          $(this).addClass("is-active");
        });

        $("html").addClass("gallery-open");

        // Call Analytics on Load
        const gallery_title = GData._title || "Kein Titel"; // Getting the title of the first image
        if ($.isFunction(window.callAnalytics)) callAnalytics(gallery_title);
      });

      const _close_gallery = () => {
        document.dispatchEvent(_gallery_before_close);
        $(".gallery-slideout .image-list").scrollTop(0);
        $(".gallery-slideout.is-active").removeClass("is-active").fadeOut(500);
        $("html").removeClass("gallery-open");
      };

      // On Pressing [ESC]
      $(document).keyup(function(event) {
        if (event.keyCode === 27) {
          _close_gallery();
        }
      });

      // On Clicking on the Dark Side
      $(document).mouseup(function(e) {
        const container = $(".gallery .gallery-inner");
        if (
          !container.is(e.target) &&
          container.has(e.target).length === 0 &&
          $(".gallery").hasClass("is-active")
        ) {
          _close_gallery();
        }
      });

      // On Clicking the close Button
      $(".js--gallery-close").on("click", _close_gallery);
    },


    events: function() {
      // On Open
      document.addEventListener("gallery_sidebar_open", function(g_event) {
        let G_ID = g_event.detail.elementId;
        let GContainer = "#" + G_ID + "_container";
        let GC = document.querySelector(GContainer + " .image-list");
        let G_Title = g_event.detail.title;

        let observer = new IntersectionObserver(
          (entries, observer) => {
            if (!$(GContainer).hasClass("is-active")) return;

            entries.forEach((entry) => {
              let boxIndex = parseInt(entry.target.getAttribute("data-index"));

              if (entry.isIntersecting && boxIndex % 3 === 0) {
                const page = Math.floor(boxIndex / 3);
                const gallery_title =
                  `${G_Title} | Seite: ${page}` || "Kein Titel";
                // console.log(gallery_title);
                if ($.isFunction(window.callAnalytics))
                  callAnalytics(gallery_title);
              }



              if (
                entry.isIntersecting &&
                !entry.target.classList.contains("is-loaded") &&
                entry.target.classList.contains("advert")
              ) {
                let advertID = entry.target.id;
                let advertTarget = `#${advertID}`;

                // Add the "is-loaded" class to mark the element as loaded
                entry.target.classList.add("is-loaded");

                console.log(
                  `Detecting next advert: ${advertID} | Found: ${$(advertTarget).length}`
                );
                window.dispatchEvent(
                  new CustomEvent("advert__gallery_sidebar", {
                    detail: { ID: advertID }
                  })
                );
              }
            });
          },
          { root: GC, rootMargin: "0px 0px 500px 0px" }
        );

        let G_items = document.querySelectorAll(GContainer + " .image-list > div,"+ GContainer + " .image-list > .advert"
        );

        G_items.forEach((box, index) => {
          observer.observe(box);
          box.setAttribute("data-index", index + 1);
        });
      });
    }

  };

  require_gallery_slideout();
})(jQuery);



