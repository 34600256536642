(function ($) {
    "use strict";

    window.ONS = window.ONS || {};

    window.ONS.Domain = function (domain) {
        console.log(window.location.hostname);
        return window.location.hostname.endsWith(domain);
    };

    window.ONS.generateRandomHash = function (length) {
        const characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        return Array.from({length}, () =>
            characters.charAt(Math.floor(Math.random() * characters.length))
        ).join("");
    };

    window.ONS.generateUniqueId = async function generateUniqueId(clientData) {
        // create a single string from client data
        const clientDataString = JSON.stringify(clientData);

        // encode as (utf-8) Uint8Array
        const msgUint8 = new TextEncoder().encode(clientDataString);

        // hash the message
        const hashBuffer = await crypto.subtle.digest("SHA-1", msgUint8);

        // convert buffer to byte array
        const hashArray = Array.from(new Uint8Array(hashBuffer));

        // convert bytes to hex string
        const hashHex = hashArray
            .map((b) => b.toString(16).padStart(2, "0"))
            .join("");

        return `_shr_${hashHex}`;
    };

    let _global = {
        init: function () {
            this.action__animations();
        },

        /**
         * Stopping All CSS Transitions While Resizing the window
         */

        action__animations: function () {
            const classes = document.body.classList;
            let timer = 0;
            window.addEventListener("resize", function () {
                if (timer) {
                    clearTimeout(timer);
                    timer = null;
                } else classes.add("stop-transitions");

                timer = setTimeout(() => {
                    classes.remove("stop-transitions");
                    timer = null;
                }, 100);
            });
        },


    }

    if (window.ONBN.modules.functions === true) {
        _global.init();
    }
    
})(jQuery);
