(function ($) {
    "use strict";

    let _LAYOUT = null;

    let clientData = {
        screenResolution: screen.width + "x" + screen.height,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        userAgent: navigator.userAgent
    };

    let layout_require = function () {
        if (typeof window.Cookies !== "undefined") {
            _layout_core.init();
        } else {
            // Check again after a short delay
            setTimeout(layout_require, 50);
        }
    };

    let _layout_core = {
        init: function () {
            _LAYOUT = this;
            this.user_id = window.ONS.generateUniqueId(clientData);

            this.cleaverReach();
            this.share__mobile();
            this.important__posts();
            this.accordion();
            this.lightbox();
            this.job_timer();
        },

        cleaverReach: function () {
            // Cleaver-Reach
            $(".js--cleaver-reach").click(function () {
                $(".cleverpush-bell").trigger("click");
            });
        },

        share__mobile: function () {
            let pageTitle = $(document).prop("title");
            let metaDescription = $("meta[name=description]").attr("content");

            _LAYOUT.user_id.then((uniqueId) => {
                $(".js-m--share").data("id", uniqueId);
            });

            // Native Share Mobile Phone
            $(".js-m--share").click(function () {
                let ID = $(this).data("id");
                let shareURL =
                    window.location.origin +
                    window.location.pathname +
                    "?utm_source=navigation&utm_medium=website&utm_campaign=share&utm_content=" +
                    ID;

                if (navigator.share) {
                    navigator
                        .share({
                            title: pageTitle,
                            text: metaDescription,
                            url: shareURL
                        })
                        .then(() => console.log("Successful share"))
                        .catch((error) => console.log("Error sharing", error));
                } else {
                    // Clipboard fallback
                    const textToCopy = `${pageTitle}\n \n ${metaDescription}\n ${shareURL}`;

                    navigator.clipboard
                        .writeText(textToCopy)
                        .then(() => alert("The content has been copied to the clipboard."))
                        .catch((error) => console.log("Error copying to clipboard", error));
                }
            });
        },

        important__posts: function () {
            // Important Posts

            $(".js-m--important-posts").click(function () {
                $("html").toggleClass("is-open");
                $(".js--important-posts").fadeToggle();
            });

            $(".js--important-posts a").click(function (e) {
                e.preventDefault();
                $(".js--important-posts").fadeOut();
                $("html").removeClass("is-open");
                setTimeout((window.location = $(this).attr("href")), 300);
            });


            // Get the post id from the body class
            let bodyClass = $("body").attr("class");
            let postIdMatch = bodyClass.match(/postid-(\d+)/);
            let postId = postIdMatch ? parseInt(postIdMatch[1]) : null;

            // Get the cookie data
            let cookieData = Cookies.get("v-posts");

            // If the cookie is not set yet, initialize it as an empty array
            // Else, parse the cookie data from JSON string to array
            if (!cookieData) {
                cookieData = [];
            } else {
                cookieData = JSON.parse(cookieData).map(Number); // Parse the array and convert all elements to number
            }

            //console.log(cookieData);

            // Get all links from .js--important-posts
            let links = $(".js--important-posts").find("a");
            //console.log(links);
            // Initialize the counter
            let newPostCount = 0;

            // Iterate through each link
            links.each(function () {
                let link = $(this);
                let dataId = parseInt(link.data("id")); // Ensure dataId is a number

                // If post id matches data-id, store it in the cookie
                if (dataId == postId) {
                    // If the post id is not in the cookie data yet, add it
                    if ($.inArray(postId, cookieData) === -1) {
                        cookieData.push(postId);

                        // Update the cookie
                        Cookies.set("v-posts", JSON.stringify(cookieData), {expires: 2});
                    }
                }

                // If the post id is not in the cookie data yet, add the class "is-new" and increment the counter
                if ($.inArray(dataId, cookieData) === -1) {
                    link.addClass("is-new");
                    newPostCount++;
                }
            });

            // Log the count of new posts to the console
            if (newPostCount > 0) $(".js--important-posts-count").addClass("is-red");

            $(".js--important-posts-count").html(newPostCount);
        },

        accordion: function () {
            $(".js--accordion").each(function () {  // Use jQuery's .each() for iteration
                var accordionElement = $(this);
                var accordionTitle = accordionElement.data("title");  // Use jQuery's data()

                // Initialization with Accordion Library (Assuming it's still used)
                var accordion = new Accordion(accordionElement.get(0), {
                    duration: 400,
                    showMultiple: true,
                    onOpen: function (element) {
                        var button = $(element).find(".ac-trigger");

                        if (button.length > 0) {  // Check if the button was found
                            var title = "Accordion :: " + accordionTitle + " | " + button.text();

                            // Analytics Call
                            if (typeof window.callAnalytics === 'function') {
                                callAnalytics(title);
                            }
                        }
                    }
                });
            });
        },

        lightbox: function () {
            // if (!$.isFunction(window.callAnalytics)) console.log('callAnalytics function :: not found');

            /**
             * on Click Scroll to the first Gallery element
             */
            $(".js--go-gallery").click(function (e) {
                e.preventDefault();
                var target = $(".js--gallery-slideout, .js--gallery-slider"); // Select the target element
                if (target.length) {
                    $("html,body").animate(
                        {
                            scrollTop: target.offset().top - $(window).height() / 2 // Scroll to the target element and try to center it
                        },
                        200
                    ); // Change the duration according to your preference
                }
            });

            $("body").on("click", ".js--lightbox", function (e) {
                e.preventDefault();
                $(".js--lightbox").swipebox({
                    loopAtEnd: true,
                    afterOpen: function () {
                        if ($.isFunction(window.callAnalytics)) callAnalytics();
                    },
                    prevSlide: function () {
                        if ($.isFunction(window.callAnalytics)) callAnalytics();
                    },
                    nextSlide: function () {
                        if ($.isFunction(window.callAnalytics)) callAnalytics();
                    }
                });
            });

            $("img[class*=\"wp-image\"]").each(function (index, item) {
                let linkElement = $(this).parent();
                let link = linkElement.attr("href");
                if (typeof link !== "undefined" && link !== false) {
                    linkElement.swipebox({});
                }
            });
        },

        job_timer: function () {

            if (!$(".js--job-timer").length)
                return

            let timer = 7;
            let redirectUrl = $(".js--job-timer").data("url");

            setInterval(function () {
                if (timer > 0) {
                    $(".js--job-countdown").text(timer);
                    timer--;
                } else {
                    window.location.href = redirectUrl;
                }
            }, 1000);
        },

        
    };

    if (window.ONBN.modules.layout === true) {
        layout_require();
    }


})(jQuery);