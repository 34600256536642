(function ($) {
    "use strict";

    let header_require = function () {
        if (typeof Slideout !== "undefined") {
            _header_core.init();
        } else {
            setTimeout(header_require, 50);
        }
    };


    let _AWO = null;

    let _header_core = {
        init: function () {
            _AWO = this;
            this.scroll();
            this.menu_mobile();
        },
        scroll: function () {
            // Controlling Header on Page Scrolling

            if ($(window).width() < 1000) {
                // This is Mobile Mode
                $("body").addClass("is--header-mobile");
            } else {
                $("body").removeClass("is--header-mobile");
            }

            $(window).scroll(function () {
                if ($(window).width() > 1000) {
                    if ($(window).scrollTop() >= 300) {
                        $("body").addClass("is--header-fix");
                    } else {
                        $("body").removeClass("is--header-fix");
                    }
                }
            });
        },
        menu_mobile: function () {
            // Header Menu Mobile (SlideOut.js)

            // Define the percentage you want to use
            let percentage = 90;

            // Get the viewport width
            let viewportWidth =
                window.innerWidth || document.documentElement.clientWidth;

            // Calculate the padding in pixels
            let padding = (percentage / 100) * viewportWidth;

            $(".js-m--header-menu").width(padding + "px");

            let slideOutLeft = new Slideout({
                panel: $("#js--main-wrapper")[0],
                menu: $(".js-m--header-menu")[0],
                padding: padding,
                tolerance: 70,
                touch: false
            });

            slideOutLeft.on("beforeopen", function () {
                $("body").addClass("slideOutLeft");
            });

            slideOutLeft.on("close", function () {
                $("body").removeClass("slideOutLeft");
            });

            $(".js-m--menu-trigger").on("click", function () {
                slideOutLeft.toggle();
            });

            let menu = $(".js-m--header-menu .js--mobile-nav");

            menu.find("li.menu-item-has-children").each(function () {
                let link = $(this).find(">a").attr("href");
                let subMenu = $(this).find(">.sub-menu");
                subMenu.prepend("<li><a href=\"" + link + "\">Übersicht</a></li>");
            });
            menu.find("li.menu-item-has-children > a").on("click", function (e) {
                e.preventDefault();
                let parentMenu = $(this).parent();
                let subMenu = parentMenu.find(">.sub-menu");

                if (parentMenu.hasClass("is-open")) {
                    parentMenu.removeClass("is-open");
                    subMenu.slideUp(500);
                } else {
                    parentMenu.addClass("is-open");
                    subMenu.slideDown(500);
                }
            });
        }
    };

    if (window.ONBN.modules.header === true) {
        header_require();
    }
    
})(jQuery);