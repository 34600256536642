/*
 * Orange News Framework
 * 2020-2021
 * Author: Wasim Alhafez
 */


window.ONBN = {
    debug: false,
    modules: {
        ads: true,
        functions: true,
        jobs: true,
        article: true,
        gallery: true,
        awo: false,
        hashtags: true,
        darkmode: true,
        layout: true,
        header: true,
        region: true,
        rss: false,
        santapress: false,
        search: true
    }
};

(function ($) {
    "use strict";

    let ONS_BN = {

        init: function () {


        },

    };

    ONS_BN.init();


})(jQuery);
