(function($) {
  "use strict";
  let require_gallery_slider = function() {
    if (typeof Swiper !== "undefined") {
      _gallery_slider.init();
    } else {
      // Check again after a short delay
      setTimeout(require_gallery_slider, 50);
    }
  };


  let _GS = null;
  let _gallery_slider = {

    __Selector: ".js--slider-gallery",

    init: function() {

      if (window.ONBN.modules.gallery) {
        _GS = this;
        _GS.setup();
        _GS.events();
      }
    },

    setup: function() {
      document.querySelectorAll(_GS.__Selector).forEach(_GS.build);
    },


    build: function(item) {

      const _id = item.getAttribute("id");

      let GData = {
        _id: _id,
        _title: item.dataset.title,
        _container: _id + "_container",
        _e_container: "#" + _id + "_container",
        _data: eval(_id),
        triggeredAdverts: new Set()
      };


      const _gallery_before_close = new CustomEvent(
        "gallery_slider_close",
        {
          bubbles: true,
          cancelable: false,
          detail: {
            elementId: GData._id,
            title: GData._title,
            message: "Side Gallery Will be Closed"
          }
        }
      );

      const _gallery_before_open = new CustomEvent(
        "gallery_slider_open",
        {
          bubbles: true,
          cancelable: false,
          detail: {
            elementId: GData._id,
            title: GData._title,
            message: "Side Gallery Will be Opened"
          }
        }
      );


      $(item).on("click", function() {

        document.dispatchEvent(_gallery_before_open);

        $("#" + GData._container).fadeIn(500).addClass("is-active");

        $("html").addClass("gallery-open");

        // Call Analytics on Load
        const gallery_title = GData._title || "Kein Titel"; // Getting the title of the first image
        if ($.isFunction(window.callAnalytics)) callAnalytics(gallery_title);


        let GallerySlider = new Swiper(GData._e_container + " .gallery-swiper", {
          cssMode: true,
          slidesPerView: 1,
          freeMode: false,
          loop: true,
          draggable: true,
          keyboard: {
            enabled: true,
            onlyInViewport: true // Only activate keyboard control when the swiper is in viewport
          },
          /* effect: "fade",*/
          autoHeight: true,
          navigation: {
            nextEl: ".swiper-b-next",
            prevEl: ".swiper-b-prev"
          },
          pagination: {
            el: ".swiper-pages",
            type: "fraction"
          }

        });


        GallerySlider.on("realIndexChange", function() {
          let gallery_title = GData._title || "Kein Titel"; // Getting the title of the first image
          if (typeof window.callAnalytics === "function")
            callAnalytics(gallery_title);
        });


        let advertIndexes = [];
        let triggeredAdverts = [];

        GallerySlider.slides.forEach((slide, index) => {
          if (slide.classList.contains("slide-advert")) {
            // Use Swiper's internal method to get the actual index
            const realIndex = slide.getAttribute("data-swiper-slide-index");
            advertIndexes.push(parseInt(realIndex));
          }
        });

        console.log("Advert indexes:", advertIndexes);


        function checkProximityToAdverts(currentIndex) {

          /* window.dispatchEvent(
                  new CustomEvent("advert__gallery_slider", {
                    detail: { ID: advertIndex }
                  })
                );*/

          if (
            (window.adsdefinition &&
              window.adsdefinition._gallery &&
              window.adsdefinition._gallery.is_active &&
              Array.isArray(window.adsdefinition._gallery.type) &&
              window.adsdefinition._gallery.type.includes("slider"))
            ||
            // Legacy Tagman.js
            typeof advertising !== "undefined"
          ) {

            advertIndexes.forEach(advertIndex => {
              const distance = advertIndex - currentIndex;

              // Check if the advert is within the next 2 or previous 2 slides
              if ((distance > 0 && distance <= 2) || (distance < 0 && distance >= -2)) {
                if (!triggeredAdverts.includes(advertIndex)) {
                  // Find the element with the class "advert" inside the advert slide
                  const advertSlide = Array.from(GallerySlider.slides).find(slide => {
                    return parseInt(slide.getAttribute("data-swiper-slide-index")) === advertIndex;
                  });

                  if (advertSlide) {
                    let advertElement = advertSlide.querySelector(".advert");

                    if (advertElement) {
                      let advertId = advertElement.id;
                      console.log(`You are close to an advert at index ${advertIndex}, with element ID: ${advertId}.`);
                      window.dispatchEvent(
                        new CustomEvent("advert__gallery_slider", {
                          detail: { ID: advertId }
                        }));

                    } else {
                      console.log(`You are close to an advert at index ${advertIndex}, but no element with the class "advert" was found.`);
                    }

                    // Mark this advert as triggered
                    triggeredAdverts.push(advertIndex);
                  }
                }
              }
            });

          } else {
            console.log("No adverts found in the gallery.");
          }

        }


        // Step 2: Call the function at the start of the slider
        checkProximityToAdverts(GallerySlider.realIndex);

        // Step 3: Detect when close to an advert index during slide change
        GallerySlider.on("realIndexChange", function() {
          checkProximityToAdverts(GallerySlider.realIndex);
        });

      });


      const _close_gallery = () => {
        document.dispatchEvent(_gallery_before_close);

        // Destroy the Swiper instance
        const swiperElement = document.querySelector(GData._e_container + " .gallery-swiper");

        if (swiperElement && swiperElement.swiper) {
          swiperElement.swiper.destroy(true, true);
        }

        $(".gallery-slider.is-active").removeClass("is-active").fadeOut(500);
        $("html").removeClass("gallery-open");
      };


      // On Pressing [ESC]
      $(document).keyup(function(event) {
        if (event.keyCode === 27) {
          _close_gallery();
        }
      });

      // On Clicking on the Dark Side
      $(document).mouseup(function(e) {
        const container = $(".swiper-wrapper,.swiper-ui,.swiper-top-ui");
        if (
          !container.is(e.target) &&
          container.has(e.target).length === 0 &&
          $(".gallery").hasClass("is-active")
        ) {
          _close_gallery();
        }
      });

      // On Clicking the close Button
      $(".swiper-close").on("click", _close_gallery);
      $(".swiper-fullscreen").on("click", function() {
        openFullscreen(document.documentElement);
      });

      function openFullscreen(element) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) { // Firefox
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) { // Chrome, Safari, and Opera
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { // IE/Edge
          element.msRequestFullscreen();
        }
      }


    },


    events: function() {
      // Add your events here
    }


  };

  require_gallery_slider();


})(jQuery);