(function ($) {
    "use strict";

    let _POLL = null;


    let poll_require = function () {
        if (typeof window.Cookies !== "undefined") {
            _poll_core.init();
        } else {
            // Check again after a short delay
            setTimeout(poll_require, 50);
        }
    };

    const $pollContainer = $('.js--poll').length ? $('.js--poll') : null;
    let post = $('article.post').length && $('article.post').attr('id') ? $('article.post').attr('id').replace('post-', '') : null;
    let results_container = $('.poll_results li').length ? $('.poll_results li') : null;
    let _title = $('.post--title').length ? $('.post--title').text() : '';

    let _poll_core = {
        init: function () {
            _POLL = this;
            if ($pollContainer.length) {
                this.submit_poll();
                this.check_status();
                this.show_results();
                this.add_answer_to_url();
            }
        },

        submit_poll: function () {

            $('.js--poll button').on('click', function () {

                if ($.isFunction(window.callAnalytics)) {
                    callAnalytics("poll :: " + _title);
                }

                let answer = $(this).data('answer');
                let post_id = $(this).data('post');

                // Add 'is-processing' class to indicate the process is ongoing

                $pollContainer.addClass('is-processing');


                let postData = {
                    action: 'submit_poll',
                    post_id: post_id,
                    poll_answer: answer,
                };

                $.post(ajax_poll.ajaxurl, postData, function (response) {
                    // Remove 'is-processing' and handle success or error
                    //$pollContainer.removeClass('is-processing');

                    if (response.success) {

                        // Add post ID to the cookie
                        let pollPosts = Cookies.get('poll-posts');
                        let pollPostsArray = pollPosts ? pollPosts.split(',') : [];


                        if (!pollPostsArray.includes(String(post_id))) {
                            pollPostsArray.push(post_id);
                        }

                        // Update the cookie with the new post ID list
                        Cookies.set('poll-posts', pollPostsArray.join(','), {expires: 365});

                        // Create a unique cookie for each post_id
                        let cookieName = 'poll-answer-' + post_id;

                        let timestamp = Date.now();
                        let cookieData = {
                            answer: answer,
                            timestamp: timestamp
                        };

                        // Set the cookie with the answer for 7 days
                        Cookies.set(cookieName, JSON.stringify(cookieData), {expires: 7});

                        location.reload();

                    } else {
                        alert('There was an error: ' + response.data);
                    }
                });
            });


        },

        add_answer_to_url: function () {


            let cookieName = 'poll-answer-' + post;

            let poll_answer = Cookies.get(cookieName);


            if (poll_answer) {
                let cookieData = JSON.parse(poll_answer);
                window.location.hash = cookieData.answer;
            }
        },

        hashchange: function () {
            let hash = window.location.hash.substring(1);

            results_container.each(function () {
                $(this).children('.poll_result_bar').removeClass('selected');
            });

            results_container.children('.poll_result_bar').eq(parseInt(hash) - 1).addClass('selected');
        },


        show_results: function () {


            results_container.each(function () {
                let result = $(this).data('result');


                $(this).children('.poll_result_bar').css('width', result + '%');


            });

            _POLL.hashchange();

            window.addEventListener('hashchange', function () {
                    _POLL.hashchange();
                }
            );

        },

        check_status: function () {
            let pollPosts = Cookies.get('poll-posts');
            let currentPostId = $pollContainer.find('button').data('post');

            if (pollPosts && pollPosts.split(',').includes(String(currentPostId))) {
                // Retrieve the unique cookie for the current post
                let cookieName = 'poll-answer-' + currentPostId;
                let poll_answer = Cookies.get(cookieName);

                if (poll_answer) {
                    let cookieData = JSON.parse(poll_answer);
                    let currentTime = Date.now();
                    let tenMinutes = 10 * 60 * 1000;

                    // Check if 10 minutes have passed since the timestamp
                    if (currentTime - cookieData.timestamp < tenMinutes) {
                        $pollContainer.addClass('is-done');
                    }
                }
            }
        }
    };

    if (window.ONBN.modules.poll === true) {
        poll_require();
    }


})(jQuery);